import React, { useMemo } from 'react'
import versionInfo from './version-info.json'

// import PropTypes from 'prop-types'

import { SectionTitle } from '../../components/section-title'
import { MainLayout } from '../../layouts/main'

import * as styles from './index.module.css'

export default function VersionPage() {
  return (
    <>
      <MainLayout>
        <SectionTitle>Version Information</SectionTitle>
        <div className={styles.main}>
          <div className={styles.versionInfo}>
            Build Version: <span className={styles.versionNumber}>{versionInfo.buildVersion}</span>
          </div>
        </div>
      </MainLayout>
    </>
  )
}
